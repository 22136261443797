









































import {
  defineComponent,
  ref,
  computed,
  PropType,
  useFetch,
} from '@nuxtjs/composition-api';
import { useAddresses } from '~/modules/customer/composables/useAddresses';

import { CustomerAddress } from '~/modules/GraphQL/types';

import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';

import UserAddressDetails from '~/components/UserAddressDetails.vue';

interface ListCountry {
  id: string;
  label: string;
  englishLabel: string;
  abbreviation: string;
}

export default defineComponent({
  components: { UserAddressDetails },
  props: {
    title: {
      type: String,
      default: 'address book',
    },
    countries: {
      type: Array as PropType<ListCountry[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const useAddressesComposable = useAddresses();

    const userAddresses = ref<CustomerAddress[]>([]);

    useFetch(async () => {
      const [addressesResponse] = await Promise.all([
        useAddressesComposable.load(),
      ]);
      userAddresses.value = userAddressesGetters.getAddresses(addressesResponse);
    });

    const defaultAddresses = computed((): CustomerAddress[] => userAddresses.value.filter(
      ({ default_billing, default_shipping }) => default_billing || default_shipping,
    ));

    const additionalAddresses = computed((): CustomerAddress[] => userAddresses.value.filter(({ id }) => !defaultAddresses.value
      .map((_add) => _add.id)
      .includes(id)));

    const includesDefaultAddresses = computed(() => defaultAddresses.value.length > 0);

    const useAddress = (address: CustomerAddress) => {
      emit('use-address', address);
    };

    const addAddress = () => {
      emit('add-address');
    };

    const addressFormat = (address: {
      street_line: string;
      secondary?: string;
      city: string;
      state: string;
      zipcode: string;
    }) => `${address?.street_line} ${address?.secondary}, ${address?.city}, ${address?.state} ${address?.zipcode}`.trim();

    return {
      includesDefaultAddresses,
      defaultAddresses,
      additionalAddresses,
      useAddress,
      addAddress,
    };
  },
});
